/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Root Properties zum widerverwenden*/

:root {
    --ff-primary: 'Source Sans Pro', sans-serif;
    
    --fs--small: 1rem;
    --fs--body: 1.25rem;
    --fs--teaser: 1.1rem;
    --fs--h3: 1.5rem;
    --fs--h1: 2.5rem;
  
    --fw--light: 300;
    --fw--normal: 400;
    --fw--bigger: 600;
    --fw--big: 700;
  
    --color--red: #FF3333;
    --color--dark: #2e3f4f;
    --color--btn: #06abdd;
    --color--light: rgba(253,253,253);
    --color--background: rgba(195, 195, 195, 0.7) 20%;
    --color--background--highlight: rgb(240, 243, 245);
  
    --lspacing--small: 0.3px;
    --lspacing--reg: 0.5px; 
    --line--height--small: 1.4;
}

@media (max-width: 1000px) {

  :root {
    --fs--h1: 1.8rem;
  }
}

@media (max-width: 700px) {

  :root {
    --fs--h1: 1.5rem;
  }
}

body, 
html {
    margin: 0;
    padding-top: 3em;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    color: var(--color--dark);
    line-height: 1.4;
    background: #ffffff;
}

.box {
  background: var(--color--background--highlight);
  font-family: var(--ff-primary);
  width: 400px;
  margin: 0 auto;
  padding: 0.7em 1em;
  border-radius: 5px;
}

.iframe_explain {
  width: 100%;
  background: transparent;
  border: 1px solid var(--color--dark);
  padding: 1em;
  position: relative;
}

.iframe_explain:hover {
  transition: 300ms;
  background: #DCFDD9;
}


h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.main {
  text-align: center;
}

.container{
    max-width: 1100px;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 0 auto;
}

/* Form */

.form--element {
  position: relative;
  box-sizing: content-box;
}

.form--input {
  margin-bottom: 1.5em;
  font-size: var(--fs--small);
  border: none;
  background: var(--color--background--highlight);
}

.form--input--last {
  margin-bottom: 2.5em;
}

.form--submit {
  border: none;
  cursor: pointer;
  padding: 0.7em 1em;
  font-weight: var(--fw--bigger);
  color: var(--color--light);
  background: var(--color--btn);
  font-size: var(--fs--teaser);
}

/* Check */

.check--element {
  margin-top: 2em;
  margin-bottom: 2.5em;
}

/* Typo */

h1 {
  margin-bottom: 0.8em;
  font-size: var(--fs--h1);
}

h3 {
  font-weight: var(--fw--normal);
  font-size: var(--fs--h3);
}

.bold {
  font-weight: var(--fw--bigger);
}

.text {
  font-size: var(--fs--body);
}

.check--text {
  margin-bottom: 1em;
}
.check--text:last-of-type {
  margin-bottom: 0;
}

.course--param--title {
  margin-bottom: 0.3em;
}

.err {
  color: var(--color--red);
  margin-bottom: 5em;
}


.course--param--text {
  margin-top: 3em;
  margin-bottom: 1em;
}

.course--param--text--margin--top {
  margin-top: 0;
}
.course--param--input {
  margin-top: 2em;
}

.copied--text {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--color--background--highlight);
  background: var(--color--dark);
  padding: 0.3em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  border-radius: 5px;
  opacity: 0;
  transition: 300ms;
}

.showCopied {
  opacity: 1;
}

/* Option Button */

.button--box {
  display: flex;
  padding: 0;
}

.button--option {
  font-family: var(--ff-primary);
  width: 50%;
  cursor: pointer;
  border: none;
  font-weight: var(--fw--bigger);
  font-size: var(--fs--teaser);  
  padding: 0.7em 1em;
}

.button--further {
  background: var(--color--btn);
  color: var(--color--light);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.button--back {
  background: var(--color--light);
  color: var(--color--btn);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid var(--color--btn);
}

/* Download */

.download {
  display: block;
  background: var(--color--btn);
  margin-top: 1em;
  font-size: var(--fs--teaser);
  font-weight: var(--fw--bigger);
  margin-top: 1em;
  color: var(--color--light);
  text-decoration: none;

}

@media (max-width: 450px) {
  .box {
    width: 100%;
  }
}